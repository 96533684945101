<template>
    <div class="page" v-if="isReady">
        <div class="info-wrapper">
            <h1>其他資訊</h1>

            <div class="list">
                <ul class="title">
                    <li>資料項目</li>
                    <li>更新日期</li>
                    <!--<li>簽署</li>-->
                </ul>
                <ul v-for="(d, index) in data" :key="index">
                    <li><div v-if="d.sign !== true" class="new">新</div><router-link :to="'/other-detail/'+d.id+'/'">{{ d.tc_title }}</router-link></li>
                    <li><router-link :to="'/other-detail/'+d.id+'/'">{{ getLastUpdateDate(d.last_update_date) }}</router-link></li>
                    <!--
                    <li>
                        <div v-if="d.need_sign == 'Y'" class="sign">
                            <router-link :to="'/other-detail/'+d.id+'/'" v-if="d.sign !== true">簽署</router-link>
                            <div v-else>已簽</div>
                        </div>
                    </li>
                    -->
                </ul>
            </div>

            <div class="list-paging" v-if="totalPage > 1">
                <a @click="prev()" class="button">上一頁</a>
                <a @click="swapPage(i)" v-for="i in totalPage" :key="i" :class="[(page == i) ?'active' : '']">{{ i }}</a>
                <a @click="next()" class="button">下一頁</a>
            </div>

        </div>
    </div>
</template>

<script>
import Fn from './Fn'
export default {
    mixins: [ Fn ],
    data() {
        return {
            isReady: false,
            data: [],
            page: 1,
            pageLimit: 10,
            totalPage: 1
        }
    },
    mounted() {
        this.$root.updateNotification()
        this.getData();
    },
    methods: {
        getData() {
            this.isReady = false
            const params = new FormData()
            params.append('page', this.page)
            params.append('page_limit', this.pageLimit)
            params.append('token', this.Auth.getToken())

            this.$axios
                .post(process.env.VUE_APP_API + 'other-list' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
                .then(response => {
                    let r = response.data
                    if (r.success === true) {
                        this.totalPage = r.total_page
                        this.data = r.data
                        this.isReady = true
                    }
                })
        },
        swapPage(page) {
            this.page = page
            this.getData()
        },
        prev() {
            this.page = (this.page - 1 > 1) ? this.page - 1 : 1
            this.getData()
        },
        next() {
            this.page = (this.page + 1 < this.totalPage) ? this.page + 1 : this.totalPage
            this.getData()
        }
    }
}
</script>

<style scoped lang="scss">
@import 'Page.scss';
a { text-shadow:none; }
</style>
